import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { Suspense } from 'react'
import { Loader } from "@react-three/drei";
import { Color } from 'three'
import MyBurgerMenu from './Hamburger3.jsx'





const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>

    <MyBurgerMenu />


    <Canvas
        camera={ {
            fov: 45,
            near: 0.1,
            far: 2000,
            position: [ -3, 1.5, 4 ]
        } }
    >
        <Suspense>
        
            <Experience />
        </Suspense>
        
        
    </Canvas>


    <Loader
        dataInterpolation={(p) => `Loading ${p.toFixed(0)}%`} // Text
    />

    </>
)