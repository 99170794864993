import { OrbitControls, Sparkles, Bounds, Text, Html, ContactShadows, PresentationControls, Float, Environment, useGLTF, Stars } from '@react-three/drei'

  
export default function Experience()
{
    const computer = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf')

    return <>

        <Environment preset="city" />

        <color args={ [ '#1B1A1A' ] } attach="background" />

    <OrbitControls
        enablePan={false}
        enableRotate={false}
        minDistance={ 2.6 }
        maxDistance={ 10 }
        
        />
        
        <PresentationControls 
            global
            rotation={ [ 0.15, -0.7, 0.1 ] }
            polar={ [ -0.4, 0.2 ] }
            azimuth={ [ -1, 0.75 ] }
            config={ { mass: 2, tension: 400 } }
            snap={ { mass: 4, tension: 400 } }
            >
            <Float rotationIntensity={ 0.5 }>
                <rectAreaLight 
                    width={ 2.5 }
                    height={ 1.65 }
                    intensity={ 65 }
                    color={ '#ffffff' }
                    rotation={ [ 0.1, Math.PI, 0 ] }
                    position={ [ 0, 0.55, -1.15 ] }
                />


                <Bounds fit clip observe damping={6} margin={ 1.1 }>

                    <primitive 
                        object={ computer.scene } 
                        position-y={ -1.8 }
                    >
                
                        <Html
                            transform
                            zIndexRange={[1, 2]}
                            wrapperClass="htmlScreen"
                            distanceFactor={ 1.17 }
                            position={ [ 0, 1.56, -1.4 ] }
                            rotation-x={ - 0.256 }
                        >
                            <iframe src="https://saulworks.com"/>
                        </Html>
                    </primitive>



                    <Text 
                        font="./bowlby-one-sc-v19-latin-regular.woff"
                        fontSize={ 0.45 }
                        position={ [ 0, 1.5, -1 ] }
                        rotation-y={ 0 }
                        rotation-x={ -0 }
                        maxWidth={ 8 }
                        textAlign="center"
                        color={ 'yellow' }
                    >
                        Saul Burrows
                    </Text>
                    

                </Bounds>

            </Float>

  
        

        </PresentationControls>

{/* 
        <ContactShadows
            position-y={ - 1.4 } 
            opacity={ 0.4 }
            scale={ 5 }
            blur={ 2.4 }    
        />
 */}
 
<Sparkles
            size={ 6 }
            scale={ [ 100, 50, 15 ] }
            position-y={ 1 }
            position-z={ -10 }
            speed={ 0.2 }
            count={ 1000 }
        />

    </>
}
